<template>
  <div
    v-observe-visibility="refreshOnVisibility"
    class="swiper__container"
    :class="dynamicWrapperModificators"
  >
    <div
      v-swiper:customSwiper="swiperOption"
      class="swiper__custom-swiper"
      :key="refreshKey"
    >
      <ul class="swiper__list swiper-wrapper">
        <li
          v-for="(item, i) in swipeList"
          :key="`item-${item}-${i}`"
          class="swiper__list__item swiper-slide"
          :style="dynamicSlideStyle"
        >
          <slot :slide="item" :index="i" />
        </li>
      </ul>
    </div>
    <nav class="swiper-buttons">
      <template v-if="displayArrows">
        <slot name="swiper-prev" v-if="$slots['swiper-prev']" />
        <button v-else class="swiper-buttons__button swiper-prev">
          <a-icon
            :icon="ICON.ARROW_LEFT_BLUE"
            :color="ICON_COLOR.BLUE"
            no-hover
            class="swiper-buttons__button__icon"
          />
        </button>
        <slot name="swiper-next" v-if="$slots['swiper-next']" />
        <button v-else class="swiper-buttons__button swiper-next">
          <a-icon
            :icon="ICON.ARROW_RIGHT_BLUE"
            :color="ICON_COLOR.BLUE"
            no-hover
            class="swiper-buttons__button__icon"
          />
        </button>
      </template>
      <div
        v-if="pagination"
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
      />
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import * as types from '@/store/mutation-types'

// ToDo: replace these icons with css
import AIcon, { ICON, ICON_COLOR } from 'shared/AIcon'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export default {
  name: 'ASwiper',
  mixins: [hydrationHelpers],
  components: { AIcon },
  props: {
    swipeList: {
      type: Array,
      required: true
    },
    displayArrows: {
      type: Boolean,
      required: false,
      default: true
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    pagination: {
      type: Boolean,
      default: false
    },
    noMargins: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ICON,
      ICON_COLOR,
      defaultOptions: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        speed: 1000,
        loop: false,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      },
      visibilityKey: this.$helper.guid(),
      wasSwiperShown: false
    }
  },
  computed: {
    ...mapGetters({
      isSwiperInstalled: 'third-parties/isSwiperInstalled'
    }),
    dynamicWrapperModificators() {
      return {
        'swiper--no-margins': this.noMargins
      }
    },
    combinedOption() {
      return { ...this.defaultOptions, ...this.customOptions }
    },
    swiperOption() {
      return { ...this.combinedOption, slidesPerView: 'auto' }
    },
    dynamicSlideStyle() {
      if (!isNaN(this.combinedOption.slidesPerView)) {
        return {
          width: `${100 / this.combinedOption.slidesPerView}%`,
          minWidth: `${100 / this.combinedOption.slidesPerView}%`
        }
      }
      return {}
    },
    refreshKey() {
      return this.swiperOption.slidesPerGroup
        .toString()
        .concat(
          this.swiperOption.slidesPerView,
          this.$_hydrationHelpers_windowWidth,
          this.visibilityKey
        )
    }
  },
  methods: {
    ...mapMutations({
      setSwiperAsInstalled: `third-parties/${types.SET_SWIPER_AS_INSTALLED}`
    }),
    refreshOnVisibility(visibility) {
      if (visibility && !this.wasSwiperShown) {
        this.wasSwiperShown = true
        this.visibilityKey = this.$helper.guid()
      }
    }
  },
  beforeMount() {
    if (this.isSwiperInstalled) return

    const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
    Vue.use(VueAwesomeSwiper)

    this.setSwiperAsInstalled()
  }
}
</script>

<style lang="scss">
@import 'swiper/dist/css/swiper.css';

.swiper__container {
  position: relative;
  width: 100%;
}

.swiper__custom-swiper {
  overflow: hidden;
  width: calc(100% - 30px);
  margin: 0 15px;
}

.swiper-prev {
  left: 1px;
}

.swiper-next {
  right: 1px;
}

.swiper-prev,
.swiper-next {
  width: 16px;
  height: 100%;
  position: absolute;
  background-color: white;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  transition: opacity 0.2s;
}

.swiper-button-disabled {
  opacity: 0.5;
}

.swiper-buttons__button:focus {
  outline: 1px solid $c--main;
}

.swiper__list {
  display: flex;
  align-items: stretch;
}

.swiper__list__item {
  position: relative;
  display: flex;
  justify-content: center;
  height: auto;
}

.swiper-pagination {
  bottom: -22px;
  left: 378px;
  z-index: 3;
}

.swiper__container.swiper--no-margins .swiper__custom-swiper {
  width: 100%;
  margin: 0;
}

.swiper-pagination.swiper-pagination-bullets {
  left: 0;
  bottom: -32px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet-custom {
  width: 10px;
  height: 10px;
  margin: 0 7px;
  text-align: center;
  line-height: 10px;
  font-size: 12px;
  background: #000;
}

.swiper-pagination-bullet-custom.swiper-pagination-bullet-active {
  background: #5664ac;
}
</style>
