<template>
  <div class="primary-events">
    <div class="primary-events__top-section">
      <div class="primary-events__top-section-img">
        <a-image
          :image="event.Image"
          :aspect-ratio="$options.consts.aspectRatios.homepageMainEvent"
          :url="$options.consts.ROUTE.EVENTS"
          :rounded="true"
        />
      </div>

      <div class="primary-events__top-section-text">
        <h3 class="subtitle-2 blue primary-events__top-section-title">
          {{ event.Title }}
        </h3>
        <address class="subtitle-2">
          {{ event.Location }}
        </address>
        <time class="subtitle-2">
          {{ mainEventDateRange }}
        </time>
      </div>
    </div>

    <div class="primary-events__bottom-section">
      <p class="text-body pre-line">{{ event.Body }}</p>
      <a-button
        :href="event.RegisterNowButton.Url"
        :directive="event.RegisterNowButton.Directives"
        :openInNewTab="event.RegisterNowButton.OpenInNewTab"
        class="primary-events__button"
      >
        {{ event.RegisterNowButton.Title }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { aspectRatios } from 'enums/aspectRatios'
import { ROUTE } from 'enums/routes'

export default {
  name: 'APrimaryEvents',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  consts: { ROUTE, aspectRatios },
  computed: {
    mainEventDateRange() {
      return this.$helper.formatDate.toDateRange(
        this.event.StartDate,
        this.event.EndDate
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$section-size: 672px;
$image-size: 332px;

.primary-events {
  display: flex;
  flex-direction: column;
  width: percentage($section-size/$desktop-full-hd-container);
  padding-right: 40px;

  @include desktop-lg {
    width: percentage(1 / 3);
    padding-right: 20px;
  }

  @include desktop-md {
    width: 100%;
    margin-bottom: 40px;
  }

  @include tablet {
    padding-right: 0;
    margin-bottom: 20px;
  }

  @include mobile {
    width: 100%;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .primary-events__top-section {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    @include desktop-lg {
      flex-direction: column;
    }

    @include desktop-md {
      flex-direction: row;
    }

    @include mobile {
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }

  .primary-events__top-section-img {
    width: percentage($image-size/($section-size - 40px));
    flex-shrink: 0;

    @include desktop-lg {
      width: 100%;
      margin-bottom: 10px;
    }

    @include desktop-md {
      width: 42%;
    }

    @include mobile {
      width: 100%;
    }
  }

  .primary-events__top-section-text {
    margin-left: 20px;

    @include desktop-lg {
      margin: 0;
    }

    @include desktop-md {
      width: 58%;
      padding-left: 20px;
    }

    @include mobile {
      & > * {
        font-size: 19px;
      }
    }

    @include mobile {
      width: 100%;
      padding-left: 0;
    }
  }

  .primary-events__top-section-title {
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .primary-events__bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .primary-events__button {
    margin-top: 20px;

    @include mobile {
      width: 100%;
    }
  }

  .subtitle-2 {
    line-height: 22px;
  }
}
</style>
