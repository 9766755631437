<template>
  <h2 class="homepage-section-title__wrapper">
    <a-link
      :to="link"
      :open-in-new-tab="openInNewTab"
      class="homepage-section-title__title"
    >
      {{ title }}
    </a-link>
    <i v-if="delimiter" class="homepage-section-title__delimiter" />
  </h2>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'ASectionTitle',
  props: {
    title: propValidator([PROP_TYPES.STRING]),
    link: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    openInNewTab: propValidator([PROP_TYPES.BOOLEAN], false),
    delimiter: propValidator([PROP_TYPES.BOOLEAN], false, false)
  }
}
</script>

<style lang="scss" scoped>
.homepage-section-title__wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .homepage-section-title__title {
    font-size: 28px;

    @include mobile {
      font-size: 25px;
    }
  }

  .homepage-section-title__delimiter {
    content: '';
    display: block;
    background: $c--main;
    height: 2px;
    margin-left: 20px;
    flex-grow: 1;

    @include mobile {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
</style>
