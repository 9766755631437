<template>
  <div class="testimonials__wrap">
    <h3 class="subtitle-1 white testimonials__heading">
      {{ testimonialsSectionTitle }}
    </h3>
    <div class="testimonials__swiper__wrap">
      <div
        class="testimonials__swiper__pagination swiper-pagination"
        slot="pagination"
      />

      <a-swiper
        :swipeList="testimonialsSettings.Items"
        :customOptions="swiperOptions"
        class="testimonials__list swiper-wrapper"
      >
        <div
          class="testimonials__list__item swiper-slide"
          slot-scope="{ slide }"
        >
          <div class="testimonials__list__item__image">
            <a-image
              object-fit="cover"
              :aspect-ratio="1"
              :image="slide.Image"
            />
          </div>
          <div class="testimonials__list__item__text">
            <p class="text-body bold">
              {{ slide.AuthorName }}
            </p>
            <p class="text-body bold">
              {{ slide.Title }}
            </p>
            <blockquote class="text-body testimonials__list__item__quote">
              {{ slide.Description | wrapInQuotes }}
            </blockquote>
          </div>
        </div>

        <div v-show="false" slot="swiper-prev" />
        <div v-show="false" slot="swiper-next" />
      </a-swiper>
    </div>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import ASwiper from 'shared/ASwiper'

const DEFAULT_TESTIMONIALS_SECTION_TITLE =
  'See what people are saying about FMevents'

export default {
  name: 'ATestimonialsEvents',
  components: { ASwiper },
  props: {
    testimonialsSettings: propValidator([PROP_TYPES.OBJECT]),
    eventName: propValidator([PROP_TYPES.STRING])
  },
  filters: {
    wrapInQuotes(value) {
      return `‘${value}’`
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 1000,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    testimonialsSectionTitle() {
      return (
        this.testimonialsSettings.Label || DEFAULT_TESTIMONIALS_SECTION_TITLE
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$section-size: 897px;

.testimonials__wrap {
  width: percentage($section-size/$desktop-full-hd-container);
  height: 263px;
  display: flex;
  background-color: $c--black;
  color: white;
  padding: 40px 28px 45px 30px;

  @include desktop-lg {
    width: 90%;
  }

  @include desktop-md {
    width: 100%;
  }

  @include desktop-sm {
    width: 100%;
  }
}

.testimonials__heading {
  padding-right: 40px;
  width: 275px;
}

.testimonials__swiper__wrap {
  position: relative;
  width: 72.25%;
}

.testimonials__swiper__pagination {
  position: absolute;
  bottom: -15px;
  left: 17px;
  display: flex;
  width: 140px;
  justify-content: center;

  /deep/ .swiper-pagination-bullet {
    border: 1px solid white;
    opacity: 1;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  /deep/ .swiper-pagination-bullet-active {
    background-color: white;
  }
}

.testimonials__swiper {
  height: 100%;
}

.testimonials__list__item {
  display: flex;
  width: 100%;
}

.testimonials__list__item__image {
  position: relative;
  width: 100%;
  max-width: 140px;
  max-height: 140px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}

.testimonials__list__item__text {
  padding-left: 41px;
}

.testimonials__list__item__quote {
  margin-top: 10px;
}
</style>
