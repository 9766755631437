<template>
  <div class="secondary-events">
    <ul class="secondary-events__list">
      <li
        v-for="(event, index) in events.EventsList"
        :key="`${event.Title}-${index}`"
        class="secondary-events__list-item"
      >
        <h3 class="subtitle-2 blue secondary-events__title">
          {{ event.Title }}
        </h3>
        <time class="subtitle-2 normal secondary-events__date">
          {{ getEventDateRange(event) }}
        </time>
        <address class="subtitle-2 normal secondary-events__location">
          {{ event.Location }}
        </address>
      </li>
    </ul>

    <a-button
      :href="events.EventsHubButton.Url"
      :directive="events.EventsHubButton.Directives"
      :openInNewTab="events.EventsHubButton.OpenInNewTab"
      :button-style="BUTTON_STYLE.WHITE"
      class="secondary-events__button"
    >
      {{ events.EventsHubButton.Title }}
    </a-button>
  </div>
</template>

<script>
import { BUTTON_STYLE } from 'shared/AButton'

export default {
  name: 'ASecondaryEvents',
  props: {
    events: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      BUTTON_STYLE
    }
  },
  computed: {},
  methods: {
    getEventDateRange(event) {
      return this.$helper.formatDate.toDateRange(event.StartDate, event.EndDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/local/styles/_homepage-events';

$section-size: 412px;

.secondary-events {
  width: percentage($section-size/$desktop-full-hd-container);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  border-left: 2px solid #4a4a4a;

  @include desktop-lg {
    width: percentage(1 /3);
  }

  @include desktop-md {
    border-left: none;
    padding-left: 0;
    width: 50%;
  }

  @include desktop-sm {
    width: $primary-events-image-width;
    border-left: 0;
    padding-left: 0;
    padding-right: 26px;
  }

  &__list {
    /*Needed for correct word-break in EDGE*/
    width: 100%;
  }

  &__list-item {
    width: 100%;
    word-wrap: break-word;
    margin-bottom: 11px;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__location {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: auto;
    min-width: 140px;
  }
}
</style>
