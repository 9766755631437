<template>
  <section class="upcoming-events upcoming-events__wrapper">
    <a-lazy-hydrate when-visible>
      <a-section-title title="Upcoming Events" link="/events" />
    </a-lazy-hydrate>

    <div class="upcoming-events__events-container">
      <a-lazy-hydrate when-visible>
        <a-primary-events :event="events.MainEvent" />
      </a-lazy-hydrate>

      <a-lazy-hydrate when-visible>
        <a-visibility hide :on="[$breakpoint.mobile]">
          <a-secondary-events :events="events.Events" />
        </a-visibility>
      </a-lazy-hydrate>

      <a-lazy-hydrate when-visible>
        <a-visibility hide :on="[$breakpoint.mobile]">
          <a-video-events :video-settings="events.VideoSettings" />
        </a-visibility>
      </a-lazy-hydrate>
    </div>

    <a-visibility hide :on="[$breakpoint.mobile]">
      <div class="testimonials-newsletter__wrap">
        <a-lazy-hydrate when-visible>
          <a-testimonials-events
            :event-name="events.MainEvent.Title"
            :testimonials-settings="events.Testimonials"
          />
        </a-lazy-hydrate>

        <a-newsletter-events :newsletter="events.Newsletter" />
      </div>
    </a-visibility>
  </section>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import APrimaryEvents from './APrimaryEvents'
import ASecondaryEvents from './ASecondaryEvents'
import AVideoEvents from './AVideoEvents'
import ATestimonialsEvents from './ATestimonialsEvents'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'HomepageEvents',
  mixins: [serverCacheKey],
  components: {
    ASectionTitle,
    APrimaryEvents,
    ASecondaryEvents,
    AVideoEvents,
    ATestimonialsEvents,
    ANewsletterEvents: hydrateWhenVisible(() => import('./ANewsletterEvents'), {
      props: ['newsletter']
    })
  },
  props: {
    events: propValidator([PROP_TYPES.OBJECT])
  }
}
</script>

<style lang="scss" scoped>
.upcoming-events {
  &.upcoming-events__wrapper {
    @include mobile {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  .upcoming-events__events-container {
    display: flex;
    flex-wrap: wrap;
  }

  .testimonials-newsletter__wrap {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include desktop-lg {
      flex-direction: column;
      align-items: center;
    }

    @include desktop-sm {
      flex-direction: column;
    }
  }

  &__banner {
    margin-top: 40px;

    @include mobile {
      margin-bottom: 10px;
    }
  }
}
</style>
