<template>
  <div class="events-video">
    <span class="subtitle-2 blue">Video</span>
    <h3 class="subtitle-2 events-video__title">
      {{ videoSettings.Title | maxLength }}
    </h3>
    <a-video
      :video="videoSettings.Video"
      :aspect-ratio="aspectRatios.homepageUpcomingEventsVideo"
      :rounded="true"
    />
    <p class="text-body events-video__text">
      {{ videoSettings.Description | maxLength(175) }}
    </p>

    <a-button
      :href="videoSettings.VideoGalleryButton.Url"
      :directive="videoSettings.VideoGalleryButton.Directives"
      :openInNewTab="videoSettings.VideoGalleryButton.OpenInNewTab"
      :button-style="BUTTON_STYLE.WHITE"
      class="events-video__button"
    >
      {{ videoSettings.VideoGalleryButton.Title }}
    </a-button>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import AVideo from 'shared/AVideo'
import { aspectRatios } from 'enums/aspectRatios'
import { BUTTON_STYLE } from 'shared/AButton'

export default {
  name: 'AVideoEvents',
  components: { AVideo },
  props: {
    videoSettings: propValidator([PROP_TYPES.OBJECT])
  },
  data() {
    return { aspectRatios, BUTTON_STYLE }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/local/styles/_homepage-events';

$section-size: 372px;

.events-video {
  width: percentage($section-size/$desktop-full-hd-container);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 40px;
  border-left: 2px solid #4a4a4a;

  @include desktop-lg {
    width: percentage(1 /3);
    padding-left: 20px;
  }

  @include desktop-md {
    width: 50%;
    padding-left: 40px;
  }

  @include desktop-sm {
    width: calc(100% - #{$primary-events-image-width});
  }

  &__title {
    margin: 5px 0 20px 0;
  }

  &__text {
    margin: 10px 0 20px 0;
  }

  &__button {
    margin-top: auto;
    min-width: 140px;
  }
}
</style>
